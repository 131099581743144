exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutme-tsx": () => import("./../../../src/pages/aboutme.tsx" /* webpackChunkName: "component---src-pages-aboutme-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-furniture-tsx": () => import("./../../../src/pages/services/furniture.tsx" /* webpackChunkName: "component---src-pages-services-furniture-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-interior-tsx": () => import("./../../../src/pages/services/interior.tsx" /* webpackChunkName: "component---src-pages-services-interior-tsx" */),
  "component---src-pages-services-kitchen-tsx": () => import("./../../../src/pages/services/kitchen.tsx" /* webpackChunkName: "component---src-pages-services-kitchen-tsx" */),
  "component---src-pages-services-outdoor-tsx": () => import("./../../../src/pages/services/outdoor.tsx" /* webpackChunkName: "component---src-pages-services-outdoor-tsx" */),
  "component---src-pages-services-presentation-tsx": () => import("./../../../src/pages/services/presentation.tsx" /* webpackChunkName: "component---src-pages-services-presentation-tsx" */),
  "component---src-pages-services-restoration-tsx": () => import("./../../../src/pages/services/restoration.tsx" /* webpackChunkName: "component---src-pages-services-restoration-tsx" */),
  "component---src-pages-services-staircases-tsx": () => import("./../../../src/pages/services/staircases.tsx" /* webpackChunkName: "component---src-pages-services-staircases-tsx" */),
  "component---src-pages-services-windows-tsx": () => import("./../../../src/pages/services/windows.tsx" /* webpackChunkName: "component---src-pages-services-windows-tsx" */)
}

